import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import {
  addReceipt,
  deleteReceipt,
  sendReceipt,
  showReceipt,
  showUploadedReceipt,
  updateReceipt,
} from './slice'
import { memberAccountType } from './type'

//SİSTEME FATURA UPLOAD ETMEK İÇİN KULLANILIR
function* sendReceiptHandler({ payload }: any) {
  const formdata = new FormData()
  formdata.append('File', payload.file)
  formdata.append('name', payload.name)
  formdata.append('user_id', payload.user_id)
  formdata.append('receipt_id', payload.receipt_id)
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/receipt/file`, formdata)
    )
    yield put(sendReceipt(res.data.data))
  } catch (error) {
    yield put(sendReceipt([]))
  }
}

//LİSANS LİSTELEMEK İÇİN KULLANILIR
function* showReceiptHandler({ user_id, payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/receipt`, {
        params: {
          user_id: user_id,
          key: payload.key,
          invoice_status: payload.invoice_status,
          start_date: payload.start_date,
          end_date: payload.end_date,
          sold_type: payload.payment_type,
        },
      })
    )
    yield put(showReceipt(res.data.data))
  } catch (error) {
    yield put(showReceipt([]))
  }
}

//SİSTEME YÜKLENEN FATURALARI LİSTELER
function* showUploadedReceiptHandler({ receipt_id }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/receipt/file/${receipt_id}`)
    )
    yield put(showUploadedReceipt(res.data.data))
  } catch (error) {
    yield put(showUploadedReceipt([]))
  }
}

//SİSTEME YÜKLENEN FATURALARI SİLER
function* deleteReceiptHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.delete(`${application.api}/admin/receipt/file`, {
        params: {
          receipt_id: payload.receipt_id,
          user_id: payload.user_id,
        },
      })
    )
    yield put(deleteReceipt(res.data.data))
  } catch (error) {
    yield put(deleteReceipt([]))
  }
}

//LİSANS OLUŞTURMAK İÇİN KULLANILIR.
function* addReceiptHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/receipt`, {
        user_id: payload.user_id,
        name: payload.name,
        amount: payload.amount,
        apartment_quantity: payload.apartment_quantity,
        bank: payload.bank,
        desc: payload.desc,
        licence_day: payload.licence_day,
        sms_quantity: payload.sms_quantity,
      })
    )
    yield put(addReceipt(res.data.data))
    yield showReceiptHandler({ user_id: payload.user_id, payload })
  } catch (error) {
    yield put(addReceipt([]))
  }
}

//LİSANS OLUŞTURMAK İÇİN KULLANILIR.
function* editCaseHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/admin/receipt`, {
        receipt_id: payload.receipt_id,
        bank: payload.bank,
      })
    )
    yield put(updateReceipt(res.data.data))
  } catch (error) {
    yield put(updateReceipt([]))
  }
}

export function* MemberReceiptSaga() {
  yield all([takeEvery(memberAccountType.SEND_RECEIPT, sendReceiptHandler)])
  yield all([takeEvery(memberAccountType.ADD_RECEIPT, addReceiptHandler)])
  yield all([takeEvery(memberAccountType.EDIT_CASE, editCaseHandler)])
  yield all([takeEvery(memberAccountType.SHOW_RECEIPT, showReceiptHandler)])
  yield all([
    takeEvery(
      memberAccountType.SHOW_UPLOADED_RECEIPT,
      showUploadedReceiptHandler
    ),
  ])
  yield all([takeEvery(memberAccountType.DELETE_RECEIPT, deleteReceiptHandler)])
}
