import { createSlice, current } from '@reduxjs/toolkit'

interface IMember {
  notes: any[]
}

const initialState: IMember = {
  notes: [],
}

const notesSlice = createSlice({
  name: 'magagementFirmsSlice',
  initialState: initialState,
  reducers: {
    showNotes: (state, action) => {
      state.notes = action.payload
    },
    addNotes: (state, action) => {
      state.notes.push(action.payload)
    },
    deleteNotes: (state, action) => {
      state.notes = state.notes.filter((el) => el.ID !== action.payload)
    },
    updateNotes: (state: any, action: any) => {
      const indexNote: any = state.notes.findIndex(
        (el: any) => el.id === action.payload.id
      )
      state.notes[indexNote] = action.payload
    },
  },
})

export const { showNotes, addNotes, deleteNotes, updateNotes } =
  notesSlice.actions
export default notesSlice.reducer
