import { all } from 'redux-saga/effects'
import { memberCallSaga } from 'src/views/pages/members/memberCall/store/saga'
import { ticketListSaga } from 'src/views/pages/support/supportIncoming/store/saga'
import { licenceSagas } from 'src/views/pages/report/licenseReports/store/saga'
import { LoginSaga } from '../views/pages/authentication/Login/store/saga'
import { registerSaga } from '../views/pages/authentication/Register/store/saga'
import { employeeSagas } from '../views/pages/employee/employeeList/store/saga'
import { managementFirmsSaga } from '../views/pages/members/managementFirms/store/saga'
import { memberListSaga } from '../views/pages/members/memberList/store/saga'
import { notesSaga } from 'src/views/pages/members/memberNotes/store/saga'
import { memberUpdateSaga } from '../views/pages/members/memberUpdate/store/saga'
import { memberSmsSaga } from 'src/views/pages/members/memberSms/store/saga'
import { MemberReceiptSaga } from 'src/views/pages/members/memberAccount/store/saga'
import { smsSagas } from 'src/views/pages/report/smsReports/store/saga'
import { notificationSaga } from 'src/views/pages/notifications/store/saga'
import { dashboardTodoSaga } from 'src/views/dashboard/store/saga'
import { TodoListsSagas } from 'src/views/dashboard/ecommerce/containers/todo/store/saga'
import { collectionReportSagas } from 'src/views/pages/report/collectionReports/store/saga'
import { contangoReportSagas } from 'src/views/pages/report/contangoReports/store/saga'
export function* rootSaga() {
  yield all([
    LoginSaga(),
    memberListSaga(),
    registerSaga(),
    managementFirmsSaga(),
    notesSaga(),
    memberUpdateSaga(),
    employeeSagas(),
    memberCallSaga(),
    ticketListSaga(),
    memberSmsSaga(),
    MemberReceiptSaga(),
    licenceSagas(),
    smsSagas(),
    notificationSaga(),
    dashboardTodoSaga(),
    TodoListsSagas(),
    collectionReportSagas(),
    contangoReportSagas(),
  ])
}
