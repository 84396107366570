import axios from 'axios'
import toast from 'react-hot-toast'

const token: any = localStorage.getItem('auth')
axios.defaults.headers.common['authorization'] = `Bearer ${token}`

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    if (response.config.method !== 'get') {
      if (response.data.description !== undefined) {
        toast.success(response.data.description)
      }
    }

    return response
  },
  function (error) {
    if (!error?.response?.request?.responseURL?.includes('/last/notes')) {
      toast.error(error?.response?.data?.description)
    }
    if (error?.response?.data?.description === 'invalid or expired jwt') {
      window.location.href = '/giris'
    }
    return Promise.reject(error)
  }
)
