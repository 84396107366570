// ** Redux Imports
import reducers from './rootReducer'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './rootSaga'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const saga = createSagaMiddleware()

export const store = configureStore({
  reducer: reducers,
  middleware: [saga, thunk],
})
saga.run(rootSaga)

export const application = {
  api: 'https://api.kolaydernek.com',
}
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
