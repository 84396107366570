import axios, { AxiosResponse } from 'axios'
import { takeEvery, put, all, call } from 'redux-saga/effects'
import { application } from '../../../../../../redux/store'
import { addTodo, showAllTodoList, showTodoList } from './slice'
import { TodoListTypes } from './types'

function* showTodoListHandler({ date }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/todo?date=${date}`)
    )
    yield put(showTodoList(response.data.data))
  } catch (error) { }
}

function* showAllTodoListHandler({ date }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/todo?is_month=true&date=${date}`)
    )
    yield put(showAllTodoList(response.data.data))
  } catch (error) { }
}

function* addTodoHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/todo`, payload)
    )
    yield put(
      addTodo({
        content: response.data.data.content,
        ID: response.data.data.ID,
        subject: response.data.data.subject,
        Date: response.data.data.Date,
        Done: response.data.data.Done,
      })
    )
  } catch (error) { }
}

function* updateTodoListHandler({ payload, date }: any) {
  try {
    const payloadId = payload.map((data: any) => data.ID)
    yield call(() =>
      axios.put(`${application.api}/admin/todo`, {
        id: payloadId,
        done: true,
        date: date,
      })
    )
    yield showTodoListHandler({ page: 1, date })
  } catch (error) { }
}

function* deleteTodoListHandler({ payload, date }: any) {
  try {
    yield call(() =>
      axios.delete(`${application.api}/admin/todo`, { data: { id: payload } })
    )
    yield showTodoListHandler({ date })
    yield showAllTodoListHandler({ date })
  } catch (error) { }
}

function* updateSingleTodoHandler({ payload }: any) {
  try {
    yield call(() =>
      axios.put(`${application.api}/admin/todo`, {
        ID: [payload.id],
        Done: payload.done,
        Date: payload.date,
        content: payload.content,
        subject: payload.subject,
      })
    )
    yield showTodoListHandler({ date: payload.date })
  } catch (error) { }
}

export function* TodoListsSagas() {
  yield all([
    takeEvery(TodoListTypes.SHOW_TODO_LIST, showTodoListHandler),
    takeEvery(TodoListTypes.SHOW_ALL_TODO, showAllTodoListHandler),
    takeEvery(TodoListTypes.DELETE_TODO, deleteTodoListHandler),
    takeEvery(TodoListTypes.UPDATE_TODO, updateTodoListHandler),
    takeEvery(TodoListTypes.ADD_TODO, addTodoHandler),
    takeEvery(TodoListTypes.UPDATE_SINGLE, updateSingleTodoHandler),
  ])
}
