import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from 'src/redux/store'
import {
  deleteNotifications,
  showNotifications,
  updateNotifications,
} from './slice'
import { NotificationTypes } from './type'

function* showNotificationHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/notifications`, {
        end_date: payload?.end_date ? payload.end_date : moment(new Date()).format('DD.MM.YYYY'),
        is_read: payload?.is_read ? payload.is_read : 0,
        start_date: payload?.start_date ? payload.start_date : moment(new Date()).format('DD.MM.YYYY'),
      })
    )
    yield put(showNotifications(res.data.data))
  } catch (error) {
    yield put(showNotifications([]))
  }
}

function* updateNotificationsHandler({ payload, get }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/admin/notifications`, {
        ids: [payload?.id],
        read: true,
      })
    )
    yield showNotificationHandler({ payload: get })
    //yield put(updateNotifications(res.data.data))
  } catch (error) {
    yield put(updateNotifications([]))
  }
}

function* deleteNotificationsHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.delete(`${application.api}/admin/notifications?id=${payload.id}`)
    )
    yield put(deleteNotifications(payload))
  } catch (error) {
    yield put(deleteNotifications([]))
  }
}

export function* notificationSaga() {
  yield all([
    takeEvery(
      NotificationTypes.SHOW_NOTIFICATION_TEMPLATE,
      showNotificationHandler
    ),
    takeEvery(
      NotificationTypes.UPDATE_NOTIFICATION,
      updateNotificationsHandler
    ),
    takeEvery(
      NotificationTypes.DELETE_NOTIFICATION,
      deleteNotificationsHandler
    ),
  ])
}
