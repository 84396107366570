import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { IShowMemberList } from '../models/model'
import { showRemembers, updateFalseIsGet, updateIsGet } from './slice'
import { memberListType } from './type'

function* showMemberHandler({ page, key }: IShowMemberList) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/show/users`, {
        params: {
          key: key,
          page: page,
        },
      })
    )
    yield put(showRemembers(response.data.data.Records))
  } catch {
    yield put(showRemembers([]))
  }
}
function* searchMemberHandler({ page, key, id }: IShowMemberList) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/show/users`, {
        params: {
          key: key,
          id: id,
          page: page,
        },
      })
    )
    yield put(updateIsGet(true))
  } catch {
    yield put(updateIsGet(false))
  }
}

function* updateGetHandler() {
  try {
    yield put(updateFalseIsGet(false))
  } catch {}
}

export function* memberListSaga() {
  yield all([
    takeEvery(memberListType.SHOW_REMEMBERS, showMemberHandler),
    takeEvery(memberListType.SEARCH_REMEMBERS, searchMemberHandler),
    takeEvery(memberListType.GET_UPDATE, updateGetHandler),
  ])
}
