/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import {
  deleteMembers,
  deleteSmsMembers,
  lastCall,
  lastNote,
  listEmployee,
  showMembers,
  updateMembers,
} from './slice'
import { MemberUpdate } from './type'

interface showMembers {
  id: number
  payload: any
}

function* showMemberHandler({ id }: showMembers) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/show/users?id=${id}`)
    )
    yield put(showMembers([response.data.data]))
  } catch (error: any) {
    yield put(showMembers([]))
  }
}

function* showLastHandler({ id }: showMembers) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/last/notes/${id}`)
    )
    yield put(lastNote([response.data.data.Notes]))
    yield put(lastCall([response.data.data.Call]))
  } catch (error: any) {
    yield put(lastNote([]))
    yield put(lastCall([]))
  }
}

function* updateMemberHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.put(
        `${application.api}/admin/update/user?id=${payload.id}`,
        payload
      )
    )
    yield put(updateMembers([response.data.data]))
  } catch (error: any) {}
}

function* listEmployeeHandler() {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/personel`)
    )
    const filterData: any[] = []
    const filter = response.data.data.map((el: any) => {
      filterData.push({
        id: el.id,
        name: el.name + ' ' + el.surname,
      })
    })
    yield put(listEmployee(filterData))
  } catch (error: any) {
    yield put(listEmployee([]))
  }
}

function* deleteMemberHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.delete(
        `${application.api}/admin/branch/delete/admin?branch_id=${payload.branch_id}&personel_id=${payload.personel_id}&code=${payload.code}`
      )
    )
    yield put(deleteMembers(true))
  } catch (error: any) {
    yield put(deleteMembers(false))
  }
}

function* deleteSmsCodeMemberHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(
        `${application.api}/admin/branch/delete/admin?branch_id=${payload.branch_id}&personel_id=${payload.personel_id}`
      )
    )
    yield put(deleteSmsMembers(true))
  } catch (error: any) {
    yield put(deleteSmsMembers(false))
  }
}

function* callMemberHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get('https://api.bulutsantralim.com/bridge', {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        params: {
          key: 'K4aa87e96-7811-417e-a96f-6cfacb13ceb7',
          source: '902129510676',
          caller_id: '902129510676',
          destination: '905415603579',
        },
      })
    )
  } catch (error: any) {}
}
export function* memberUpdateSaga() {
  yield all([
    takeEvery(MemberUpdate.SHOW_MEMBER, showMemberHandler),
    takeEvery(MemberUpdate.UPDATE_MEMBER, updateMemberHandler),
    takeEvery(MemberUpdate.LIST_EMPLOYEE, listEmployeeHandler),
    takeEvery(MemberUpdate.DELETE_MEMBER, deleteMemberHandler),
    takeEvery(MemberUpdate.DELETE_SMS, deleteSmsCodeMemberHandler),
    takeEvery(MemberUpdate.LAST_INFOMATION, showLastHandler),
    takeEvery(MemberUpdate.CALL_MEMBER, callMemberHandler),
  ])
}
