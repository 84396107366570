import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showContangoMultiReports, showContangoReports } from './slice'
import { ContangoReportTypes } from './type'

function* showContangoReportsHandler({ payload, page }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/report/bounty`, {
        params: {
          page: page,
          amount: payload.amount,
          bank: payload.bank,
          end_date: payload.end_date,
          start_date: payload.start_date,
          personel_id: payload.personel_id,
          status: payload.pay_status,
        },
      })
    )
    yield put(showContangoReports(response.data.Records))
    yield put(
      showContangoMultiReports([
        {
          total_bounty: response.data.total_bounty,
          total_paid: response.data.total_paid,
          total_paid_bounty: response.data.total_paid_bounty,
          total_sale: response.data.total_sale,
          total_unpaid: response.data.total_unpaid,
          total_unpaid_bounty: response.data.total_unpaid_bounty,
        },
      ])
    )
  } catch (error: any) {
    yield put(showContangoReports([]))
  }
}
export function* contangoReportSagas() {
  yield all([
    takeEvery(
      ContangoReportTypes.SHOW_CONTANGO_REPORT,
      showContangoReportsHandler
    ),
  ])
}
