import axios, { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showTicketDetail, showTickets } from './slice'
import { ticketListTypes } from './type'

function* showTicketsHandler({ page, payload }: any) {
  try {
    console.log(payload)
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/ticket?page=${page}`, {
        params: {
          start_date: payload?.start_date,
          end_date: payload?.end_date,
          status: payload?.status,
          key: payload?.key,
          user_id: payload?.user_id,
        },
      })
    )
    yield put(showTickets(response.data.data.Records))
  } catch (error: any) {
    yield put(showTickets([]))
  }
}

function* showTicketDetailHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/ticket/${payload.id}`)
    )
    yield put(showTicketDetail(response.data.data))
  } catch (error) {
    showTicketDetail([])
  }
}
function* sendTicketMessageHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/ticket/send`, payload.data)
    )
    yield showTicketDetailHandler({ payload: { id: payload.data.ticket_id } })
  } catch (error) {
    showTicketDetail([])
  }
}

function* deleteTicketHandler({ payload, filter }: any) {
  try {
    yield call(() =>
      axios.delete(`${application.api}/admin/ticket`, {
        data: {
          ticket_id: payload.ticket_id,
        },
      })
    )
    yield showTicketsHandler({ page: 1, payload: filter })
  } catch (error) {
    showTicketDetail([])
  }
}
function* updateicketHandler({ payload, filter }: any) {
  try {
    yield call(() => axios.put(`${application.api}/admin/ticket`, payload.data))
    yield showTicketsHandler({ page: payload.page, payload: filter })
  } catch (error) {}
}
export function* ticketListSaga() {
  yield all([
    takeEvery(ticketListTypes.SHOW_TICKETS, showTicketsHandler),
    takeEvery(ticketListTypes.SHOW_TICKETS_DETAIL, showTicketDetailHandler),
    takeEvery(ticketListTypes.SEND_TICKET_MESSAGE, sendTicketMessageHandler),
    takeEvery(ticketListTypes.DELETE_TICKET, deleteTicketHandler),
    takeEvery(ticketListTypes.UPDATE_TICKETS, updateicketHandler),
  ])
}
